<template>
  <div class="worldcupcloth">
    <div class="pc">
      <div class="boxLeft">
        <img src="/assets/icon/cloth/left.png" />
        <img src="/assets/icon/mint/logo.png" class="logo" @click="gohome" />
        <div class="connect" v-if="!connected">
          <div class="connectin">
            <div class="welcome engBold" v-if="decipherment">
              WELCOME TO WORLD CUP
            </div>
            <div class="welcome chinBold" v-else>欢迎来到世界杯</div>
            <div class="connectWallet" @click="connect">
              <span class="engBold" v-if="decipherment">CONNECT WALLETt</span>
              <span class="chinBold" v-else>连接钱包</span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="noNft" v-if="!nftNot">
            <div class="top">
              <div class="topin">
                <div class="topl">
                  <div class="oimg engM" v-if="decipherment">
                    No <br />Monsters<br />available
                  </div>
                  <div class="oimg chinreg" v-else>
                    没有 <br />北海怪兽<br />还想换衣服？
                  </div>
                </div>
                <div class="topr">
                  <div class="address">{{ walletAddress }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="boxin" v-else>
            <div class="top">
              <div class="topin">
                <div class="topl">
                  <div class="oimg">
                    <img :src="photoImg" />
                    <div>#{{ userId }}</div>
                  </div>
                </div>
                <div class="topr">
                  <div class="address">{{ walletAddress }}</div>
                  <div
                    class="btn engBold"
                    v-if="decipherment"
                    @click="selectmonsters"
                  >
                    change
                  </div>
                  <div class="btn chinBold" v-else @click="selectmonsters">
                    换人
                  </div>
                </div>
              </div>
            </div>
            <div class="list">
              <div class="title engBold" v-if="decipherment">
                Choose your jersey
              </div>
              <div class="title chinBold" v-else>选择你的主队球衣</div>
              <div class="listin" v-show="pid == 49">
                <div
                  class="listImg"
                  v-for="(item, index) in yrCloth"
                  :key="index"
                  @click="change(item, index)"
                >
                  <div class="imgs">
                    <img :src="item.icon" />
                  </div>
                  <div class="names engreg" v-if="decipherment">
                    {{ item.name_en }}
                  </div>
                  <div class="names chinBold" v-else>{{ item.name }}</div>
                  <div class="selImg" v-show="changeIndex == index">
                    <img src="/assets/icon/cloth/selImg.png" class="sels" />
                  </div>
                </div>
              </div>
              <div class="listin" v-show="pid == 48">
                <div
                  class="listImg"
                  v-for="(item, index) in ggCloth"
                  :key="index"
                  @click="change(item, index)"
                >
                  <div class="imgs">
                    <img :src="item.icon" />
                  </div>
                  <div class="names engreg" v-if="decipherment">
                    {{ item.name_en }}
                  </div>
                  <div class="names chinBold" v-else>{{ item.name }}</div>
                  <div class="selImg" v-show="changeIndex == index">
                    <img src="/assets/icon/cloth/selImg.png" class="sels" />
                  </div>
                </div>
              </div>
              <div class="listin" v-show="pid == 47">
                <div
                  class="listImg"
                  v-for="(item, index) in mmCloth"
                  :key="index"
                  @click="change(item, index)"
                >
                  <div class="imgs">
                    <img :src="item.icon" />
                  </div>
                  <div class="names engreg" v-if="decipherment">
                    {{ item.name_en }}
                  </div>
                  <div class="names chinBold" v-else>{{ item.name }}</div>
                  <div class="selImg" v-show="changeIndex == index">
                    <img src="/assets/icon/cloth/selImg.png" class="sels" />
                  </div>
                </div>
              </div>
            </div>
            <div class="body engBold" @click="downLoad" v-if="decipherment">
              Generate!
            </div>
            <div class="body chinBold" @click="downLoad" v-else>生成！</div>
          </div>
        </div>
      </div>
      <div class="boxCenter">
        <img src="/assets/icon/cloth/center.png" />
        <div id="down_img_watch" v-show="nftNot">
          <div
            :class="index == 0 || item.change == 2 ? 'hide' : 'nftList'"
            v-show="connected"
            v-for="(item, index) in clothList"
            :key="index"
            :attr="item.change"
          >
            <img :src="list" v-for="(list, index) in item.urls" :key="index" />
          </div>
        </div>
      </div>
      <div class="boxRigth">
        <img src="/assets/icon/cloth/right.png" />
        <div class="lan" @click="changeLang">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/topbox.png"
          />
          <div class="zh engBold" v-if="decipherment">ENGLISH</div>
          <div class="zh chinBold" v-else style="top: -1px">中文</div>
        </div>
      </div>

      <!-- 换头像 -->
      <div class="tan" v-show="tanBox">
        <div class="tanin">
          <img class="img" src="/assets/icon/cloth/build_box.png" />
          <div class="tanbox">
            <div class="title">
              <p class="engBold" v-if="decipherment">select your monster</p>
              <p v-else class="chinBold">选择一个NFT</p>
            </div>
            <div
              class="boxImg"
              :class="[headarImg != '' && headarImg.length > 5 ? '' : 'center']"
            >
              <!-- <div ></div> -->
              <div
                class="boxSel"
                v-for="(item, index) in headarImg"
                :key="index"
                @click="
                  headarList(
                    item.target_info.cover.thumbnail_url,
                    item.token_id,
                    index,
                    item.used
                  )
                "
              >
                <img
                  :src="item.target_info.cover.thumbnail_url"
                  class="oimgs"
                />
                <div class="ids">#{{ item.token_id }}</div>
                <div class="selImg" v-show="selImgIndex == index">
                  <img src="/assets/icon/cloth/selImg.png" class="sels" />
                </div>
              </div>
            </div>
            <div class="tanbtn">
              <img src="/assets/icon/cloth/exbtn.png" />
              <div class="conbtn" @click="select_close">
                <p class="engBold" v-if="decipherment">Confirm</p>
                <p v-else class="chinBold">就是他了！</p>
              </div>
            </div>
            <div class="close" @click="close">
              <img src="/assets/icon/cloth/cha.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 确定下载弹窗 -->
      <div class="downTan" v-show="downTan">
        <div class="downin">
          <div class="title engBold" v-if="decipherment">
            Your World Cup outfits is generated!
          </div>
          <div class="title chinBold" v-else>行！挺合身！</div>
          <div class="box" id="tanImg">
            <div class="banner" v-for="(item, index) in clothList" :key="index">
              <img
                :src="list"
                v-for="(list, index) in item.urls"
                :key="index"
              />
            </div>
          </div>
          <div class="btn">
            <div class="left" @click="downImg">
              <span class="engBold" v-if="decipherment"
                >Download My New PFP</span
              >
              <span class="chinBold" v-else>下载新头像</span>
            </div>
            <div class="right" @click="twitter">
              <span class="engBold" v-if="decipherment">SHARE TO TWITTER</span>
              <span class="chinBold" v-else>分享到推特</span>
              <img src="/assets/icon/cloth/icon1.png" />
            </div>
          </div>
          <div class="close" @click="closedown">
            <img src="/assets/icon/cloth/cha.png" />
          </div>
        </div>
      </div>
      <div class="maxImg" id="maxImg">
        <div class="banner" v-for="(item, index) in clothList" :key="index">
          <img :src="list" v-for="(list, index) in item.urls" :key="index" />
        </div>
      </div>
    </div>
    <div class="m">
      <img src="/assets/icon/cloth/mbg.png" />
      <div class="min">
        <div class="minin">
          <img src="/assets/icon/cloth/mbox.png" />
          <div class="con">
            <div class="tip engBold" v-if="decipherment">PC ONLY!</div>
            <div class="tip chinBold" v-else>只对PC端开放！</div>
            <div class="btn" @click="gohome">
              <img src="/assets/icon/cloth/mbtn.png" />
              <div class="btnin engBold" v-if="decipherment">BACK</div>
              <div class="btnin chinBold" v-else>回首页</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { connectWallet, getCurrentAccount } from "@/utils/login.js";
import { Message } from "element-ui";
import { list_all_my_peking_monsters } from "@/api/item/index.js";
import { change_clothes } from "@/api/peking/index.js";
import html2canvas from "html2canvas";
export default {
  name: "worldCupCloth",
  data() {
    return {
      walletAddress: "",
      currentAccountnum: "",
      connected: false,
      decipherment: true,
      lang: "",
      chin: "中文",
      eng: "ENGLISH",
      tanBox: false,
      headarImg: "",
      photoImg: "",
      userId: "",
      userId_token_id: "",
      used_true: "",
      selImgIndex: 0,
      changeIndex: 0,
      photoImg_url: "",
      selCloth: "",
      clothList: "",
      changeCloth: false,
      nftNot: false,
      pid: "",
      mmCloth: [
        {
          name: "阿根廷",
          name_en: "Argentina",
          icon: "/assets/icon/cloth/icon_a.png",
          urls: ["/assets/icon/cloth/mm_a.png", "/assets/icon/cloth/mm_aa.png"],
        },
        {
          name: "法国",
          name_en: "France",
          icon: "/assets/icon/cloth/icon_f.png",
          urls: ["/assets/icon/cloth/mm_f.png", "/assets/icon/cloth/mm_ff.png"],
        },
        {
          name: "摩洛哥",
          name_en: "Morocco",
          icon: "/assets/icon/cloth/icon_m.png",
          urls: ["/assets/icon/cloth/mm_m.png", "/assets/icon/cloth/mm_mm.png"],
        },
        {
          name: "克罗地亚",
          name_en: "Croatia",
          icon: "/assets/icon/cloth/icon_k.png",
          urls: ["/assets/icon/cloth/mm_k.png", "/assets/icon/cloth/mm_kk.png"],
        },
      ],
      ggCloth: [
        {
          name: "阿根廷",
          name_en: "Argentina",
          icon: "/assets/icon/cloth/icon_a.png",
          urls: ["/assets/icon/cloth/gg_a.png", "/assets/icon/cloth/gg_aa.png"],
        },
        {
          name: "法国",
          name_en: "France",
          icon: "/assets/icon/cloth/icon_f.png",
          urls: ["/assets/icon/cloth/gg_f.png", "/assets/icon/cloth/gg_ff.png"],
        },
        {
          name: "摩洛哥",
          name_en: "Morocco",
          icon: "/assets/icon/cloth/icon_m.png",
          urls: ["/assets/icon/cloth/gg_m.png", "/assets/icon/cloth/gg_mm.png"],
        },
        {
          name: "克罗地亚",
          name_en: "Croatia",
          icon: "/assets/icon/cloth/icon_k.png",
          urls: ["/assets/icon/cloth/gg_k.png", "/assets/icon/cloth/gg_kk.png"],
        },
      ],
      yrCloth: [
        {
          name: "阿根廷",
          name_en: "Argentina",
          icon: "/assets/icon/cloth/icon_a.png",
          urls: ["/assets/icon/cloth/yr_a.png", "/assets/icon/cloth/yr_aa.png"],
        },
        {
          name: "法国",
          name_en: "France",
          icon: "/assets/icon/cloth/icon_f.png",
          urls: ["/assets/icon/cloth/yr_f.png", "/assets/icon/cloth/yr_ff.png"],
        },
        {
          name: "摩洛哥",
          name_en: "Morocco",
          icon: "/assets/icon/cloth/icon_m.png",
          urls: ["/assets/icon/cloth/yr_m.png", "/assets/icon/cloth/yr_mm.png"],
        },
        {
          name: "克罗地亚",
          name_en: "Croatia",
          icon: "/assets/icon/cloth/icon_k.png",
          urls: ["/assets/icon/cloth/yr_k.png", "/assets/icon/cloth/yr_kk.png"],
        },
      ],
      downTan: false,
      handColor: "",
    };
  },
  watch: {
    decipherment: function (oldValue) {
      if (oldValue == true) {
        this.chin = "中文";
        this.eng = "ENGLISH";
      } else {
        this.chin = "ENGLISH";
        this.eng = "中文";
      }
    },
  },
  computed: {},
  mounted() {
    this.watchWeb3Event();
    this.checkLogIn();
    this.language();
    this.list_all_my_peking_monsters();

    //中英文切换
    if (this.$route.params.decipherment) {
      this.decipherment = true;
    } else {
      this.decipherment = false;
    }
  },
  methods: {
    change(item, index) {
      this.changeIndex = index;
      for (var i = 0; i < this.clothList.length; i++) {
        if (this.clothList[i].change == 1) {
          if (this.pid == 49) {
            console.log(item);
            //胳膊
            // this.$set(arrlist[i].urls, 1, '/assets/icon/cloth/green.png');
            //衣服
            this.$set(this.clothList[i].urls, 1, item.urls[0]);
            if (this.clothList[i].urls.length > 1) {
              //袖子
              this.$set(this.clothList[i].urls, 3, item.urls[1]);
            }
          } else {
            //衣服
            this.$set(this.clothList[i].urls, 1, item.urls[0]);
            //袖子
            this.$set(
              this.clothList[this.clothList.length - 1].urls,
              1,
              item.urls[1]
            );
          }
        }
      }
      if (this.pid == 49) {
        if (item.name == "法国") {
          this.$set(
            this.clothList[this.clothList.length - 1].urls,
            2,
            "/assets/icon/cloth/yr_ff.png"
          );
        } else if (item.name == "阿根廷") {
          this.$set(
            this.clothList[this.clothList.length - 1].urls,
            2,
            "/assets/icon/cloth/yr_aa.png"
          );
        } else if (item.name == "摩洛哥") {
          this.$set(
            this.clothList[this.clothList.length - 1].urls,
            2,
            "/assets/icon/cloth/yr_mm.png"
          );
        } else if (item.name == "克罗地亚") {
          this.$set(
            this.clothList[this.clothList.length - 1].urls,
            2,
            "/assets/icon/cloth/yr_kk.png"
          );
        }
      }
    },
    downImg() {
      var ref = document.getElementById("maxImg");
      console.log(ref);
      html2canvas(ref, {
        backgroundColor: "#ffffff",
        useCORS: true, // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
      }).then((canvas) => {
        const dataURL = canvas.toDataURL("image/png");
        const creatDom = document.createElement("a");
        document.body.appendChild(creatDom);
        creatDom.href = dataURL;
        creatDom.download = "monster.png";
        creatDom.click();
      });
    },
    downLoad() {
      this.downTan = true;
    },
    async change_clothes() {
      this.changeIndex = 0;
      this.clothList = "";
      this.pid = "";
      const params = {
        token_id:this.userId,
      };
      let res = await change_clothes(params);
      if (res.code == 200) {
        this.clothList = res.data.list;
        this.pid = res.data.pid; //49野人，48嘎嘎，47咪咪
        this.handColor = res.data.color;
        if (res.data.pid == "49") {
          this.yrSelClothList(res.data.list);
        } else if (res.data.pid == "48") {
          this.selClothList(res.data.list, "gg");
        } else if (res.data.pid == "47") {
          this.selClothList(res.data.list, "mm");
        }
      }
    },
    yrSelClothList(arrlist) {
      for (var i = 0; i < arrlist.length; i++) {
        if (arrlist[i].change == 1) {
          //胳膊
          if (this.handColor == 1) {
            //绿色
            this.$set(
              arrlist[i].urls,
              0,
              "https://img.ohdat.io//preupload/44917b2c-46ea-4774-912e-35a60554e3f9..png"
            );
            this.$set(arrlist[i].urls, 1, "/assets/icon/cloth/green.png");
          } else if (this.handColor == 2) {
            //棕色
            this.$set(
              arrlist[i].urls,
              0,
              "https://img.ohdat.io//preupload/5825678e-b53f-4c2a-b4a2-536f600017e7..png"
            );
            this.$set(arrlist[i].urls, 1, "/assets/icon/cloth/brown.png");
          } else if (this.handColor == 3) {
            //粉色
            this.$set(
              arrlist[i].urls,
              0,
              "https://img.ohdat.io//preupload/e9375cf0-4f06-4186-89c9-1b620b661925..png"
            );
            this.$set(arrlist[i].urls, 1, "/assets/icon/cloth/pink.png");
          } else if (this.handColor == 4) {
            //黄色
            this.$set(
              arrlist[i].urls,
              0,
              "https://img.ohdat.io//preupload/9ad8693e-6af4-4c34-82b0-5be4f3f06beb..png"
            );
            this.$set(arrlist[i].urls, 1, "/assets/icon/cloth/yellow.png");
          } else if (this.handColor == 7) {
            //紫色
            this.$set(
              arrlist[i].urls,
              0,
              "https://img.ohdat.io//preupload/5e5546e8-579c-4cb5-afbf-84c53be994bb..png"
            );
            this.$set(arrlist[i].urls, 1, "/assets/icon/cloth/purple.png");
          } else if (this.handColor == 8) {
            //蓝色
            this.$set(
              arrlist[i].urls,
              0,
              "https://img.ohdat.io//preupload/784fff7d-20dc-4628-8145-5aa6202f1139..png"
            );
            this.$set(arrlist[i].urls, 1, "/assets/icon/cloth/blue.png");
          }
          //衣服
          arrlist[i].urls.splice(1, 0, "/assets/icon/cloth/yr_a.png");
          // this.$set(this.clothList[i].urls, 0, "/assets/icon/cloth/yr_a.png");
        }
      }
      //袖子
      arrlist[arrlist.length - 1].urls.splice(
        1,
        0,
        "/assets/icon/cloth/yr_aa.png"
      );
      if (this.handColor == 1) {
        //绿色
        arrlist[arrlist.length - 1].urls.splice(
          1,
          0,
          "/assets/icon/cloth/green.png"
        );
      } else if (this.handColor == 2) {
        //棕色
        arrlist[arrlist.length - 1].urls.splice(
          1,
          0,
          "/assets/icon/cloth/brown.png"
        );
      } else if (this.handColor == 3) {
        //粉色
        arrlist[arrlist.length - 1].urls.splice(
          1,
          0,
          "/assets/icon/cloth/pink.png"
        );
      } else if (this.handColor == 4) {
        //黄色
        arrlist[arrlist.length - 1].urls.splice(
          1,
          0,
          "/assets/icon/cloth/yellow.png"
        );
      } else if (this.handColor == 7) {
        //紫色
        arrlist[arrlist.length - 1].urls.splice(
          1,
          0,
          "/assets/icon/cloth/purple.png"
        );
      } else if (this.handColor == 8) {
        //蓝色
        arrlist[arrlist.length - 1].urls.splice(
          1,
          0,
          "/assets/icon/cloth/blue.png"
        );
      }
    },
    selClothList(arrlist, styleCloth) {
      let len = arrlist.length;
      //袖子
      if (styleCloth == "gg") {
        arrlist[len - 1].urls.splice(1, 0, "/assets/icon/cloth/gg_aa.png");
      } else {
        arrlist[len - 1].urls.splice(1, 0, "/assets/icon/cloth/mm_aa.png");
      }
      //衣服
      for (var i = 0; i < arrlist.length; i++) {
        if (arrlist[i].change == 1) {
          if (styleCloth == "gg") {
            this.$set(arrlist[i].urls, 1, "/assets/icon/cloth/gg_a.png");
            this.$set(arrlist[i].urls, 0, "/assets/icon/cloth/gg_a.png");
          } else {
            this.$set(arrlist[i].urls, 1, "/assets/icon/cloth/mm_a.png");
            this.$set(arrlist[i].urls, 0, "/assets/icon/cloth/mm_a.png");
          }
        }
      }
    },
    headarList(url, token_id, index) {
      this.photoImg_url = url;
      this.selImgIndex = index;
      this.userId_token_id = token_id;
    },
    closedown() {
      this.downTan = false;
    },
    twitter() {
      window.open(
        "https://twitter.com/share?text=My Peking Monster has a new jersey now! Come and generate a special World Cup jersey for your monsters!"
      );
    },
    close() {
      this.tanBox = false;
      this.list_all_my_peking_monsters();
    },
    select_close() {
      this.photoImg = this.photoImg_url;
      this.userId = this.userId_token_id;
      this.tanBox = false;
      this.change_clothes();
    },
    selectmonsters() {
      this.list_all_my_peking_monsters();
      this.tanBox = true;
    },
    async list_all_my_peking_monsters() {
      let data = await list_all_my_peking_monsters();
      this.headarImg = "";
      if (data.data.list != null) {
        this.headarImg = data.data.list;
        this.photoImg = data.data.list[0].target_info.cover.thumbnail_url;
        this.userId = data.data.list[0].token_id;
        this.change_clothes();
        this.nftNot = true;
      } else {
        this.nftNot = false;
      }
    },
    async checkLogIn() {
      let currentAccount = await getCurrentAccount();
      this.currentAccountnum = currentAccount;
      if (currentAccount != undefined) {
        // this.ListMyItem();
        this.walletAddress =
          currentAccount.substr(0, 5) + "****" + currentAccount.substr(-4);
      }
      if (currentAccount) {
        this.connected = true;
        if (localStorage.getItem("wallet_address") != currentAccount) {
          localStorage.setItem("wallet_address", currentAccount);
        }
      }
    },
    async connect() {
      let currentAccount = await getCurrentAccount();
      if (currentAccount == null) {
        await connectWallet();
      }
      this.connected = true;
    },
    watchWeb3Event() {
      var that = this;
      ethereum.on("disconnect", (res) => {
        console.log("已断开网络", res);
      });
      ethereum.on("connect", function (res) {
        console.log("已连接到网络", res);
      });
      ethereum.on("accountsChanged", function (res) {
        localStorage.setItem("wallet_address", res[0]);

        if (res[0]) {
          that.walletAddress = res[0].substr(0, 5) + "****" + res[0].substr(-4);
          that.connected = true;
          that.list_all_my_peking_monsters();
        } else {
          that.connected = false;
          that.walletAddress = "";
        }
      });
      ethereum.on("chainChanged", function (res) {
        const network = parseInt(res, 16);
        console.log(`networkID`, res, network);
        that.$store
          .dispatch("web3/set_network", network)
          .then((res) => {
            if (
              network == process.env.VUE_APP_MAIN_NETWORK ||
              network == process.env.VUE_APP_MAIN_NETWORK2
            ) {
              that.$store.dispatch("mask/closeMask", "mainNetwork");
              //  this.connectMetaMask()
              console.log("已切换到连接主网络");
              // window.location.reload();
            } else {
              that.$store.dispatch("mask/openMask", "mainNetwork");
            }
          })
          .catch((err) => {});
      });
      ethereum.on("message", (msg) => console.log(msg, 11111));
      // alert("成功");
    },
    changeLang() {
      this.decipherment = !this.decipherment;
    },
    language() {
      let language = null;
      if (navigator.appName == "Netscape") {
        language = navigator.language;
      } else {
        language = navigator.browserLanguage;
      }
      if (language.indexOf("en") > -1) {
        this.lang = "en";
        this.decipherment = true;
        // alert("yingwen");
      } else if (language.indexOf("zh") > -1) {
        this.lang = "zh";
        this.decipherment = false;
        // alert("zhongwen");
      } else {
        this.lang = "en";
        this.decipherment = true;
        // alert("yingwen");
      }
      console.log(this.lang);
    },
    gohome() {
      this.$router.push("/");
    },
  },
};
</script>

<style src="./index.scss" lang="scss" scoped></style>
<style src="./index.mobile.scss" lang="scss" scoped></style>
